import React from 'react'
import styled, { css } from 'styled-components'

const Container = styled.input`
    border-radius: ${p => p.theme.rounderCorners};
    border: .1em solid ${p => p.theme.gray};
    padding: 0;
    padding-left: 1em;
    font-weight: bold;
    font-size: .9em;
    color: ${p => p.theme.medium};

    :hover {
        border: .1em solid ${p => p.theme.primary};
    }

    ${p => p.height && css`
        height: ${p => p.height};
    `}
    ${p => p.width && css`
        width: ${p => p.width};
    `}
`

export default function InputField(props) {
    return (
        <Container 
            style={props.style}
            type={props.type ? props.type : 'text'}
            width={props.width}
            height={props.height} 
            placeholder={props.placeholder} 
            value={props.value ? props.value : ''}
            onChange={props.onValueChange ? e => props.onValueChange(e.target.value) : () => {}}/>
    )
}
