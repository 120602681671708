import React from 'react'
import styled, { css } from 'styled-components'

const Container = styled.div`
    background-color: ${p => p.primary ? p.theme.primary : p.theme.medium};
    border-radius: ${p => p.theme.rounderCorners};
    cursor: pointer;

    display: flex;
    justify-content: center;
    align-items: center;
    color: ${p => p.theme.light};
    font-weight: bold;
    padding: .5rem 2rem;
    white-space: nowrap;

    ${p => p.icon && css`
        display: grid;
        grid-auto-flow: column;
        grid-gap: 1rem;
        padding: .5rem 0;
    `}

    :hover {
        background-color: ${p => p.primary ? p.theme.primaryHover : p.theme.primary};
    }

    ${p => p.height && css`
        height: ${p => p.height};
    `}
`

const Icon = styled.div`
    display: ${p => p.icon ? 'block' : 'none'};
    background-color: ${p => p.theme.light};
    -webkit-mask-image: url(${p => p.icon});
    mask-image: url(${p => p.icon});
    mask-size: contain;
    mask-position: center;
    mask-repeat: no-repeat;
    height: 100%;
    width: 1.5rem;
`

export default function Button(props) {
    return (
        <Container style={props.style} icon={props.icon} primary={props.primary} height={props.height} onClick={props.onClick}>
            <Icon icon={props.icon} />
            {props.children}
        </Container>
    )
}