import React from 'react'
import styled, { css } from 'styled-components'

const highlighted = css`
    color: ${p => p.theme.primary};
`

const Container = styled.div`
    color: ${p => p.light ? p.theme.light : p.theme.gray};
    font-weight: bold;
    font-size: .9em;
    cursor: pointer;
    transition: .3s;
    white-space: nowrap;
    overflow: hidden; 
    text-overflow: ellipsis;

    :hover {
        ${highlighted}
    }

    ${p => p.highlighted && highlighted}
`

export default function TextButton(props) {
    return (
        <Container style={props.style} light={props.light} highlighted={props.selected} onClick={props.onClick}>
            {props.children}
        </Container>
    )
}